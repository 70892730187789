






import Vue from 'vue'
import stylizationList from '@/store/stylist/stylizationsList'
import SearchInstanceStore from '@/store/search/searchInstance'

export default Vue.extend({
  components: {},
  computed: {
    searchId: {
      get(): string {
        return SearchInstanceStore.state.searchInstanceId
      },
    },
    stylizationListUrl: {
      get(): string {
        return stylizationList.getters.getFrameStylizationsListUrl
      },
    },
  },
  watch: {
    searchId() {
      stylizationList.dispatch('changeSearchIdFrameTester', {
        searchId: this.searchId,
        frame: this.$refs.stylizationsList,
      })
    },
  },

  methods: {
    setFrameLoaded() {
      stylizationList.dispatch('loadedFrame', { searchId: this.searchId, frame: this.$refs.stylizationsList })
    },
  },

})
